@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
    overflow-x: hidden !important;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Saira Condensed', sans-serif;
    overflow-x: hidden !important;
}
html ::-webkit-scrollbar {
    display: none;
}

.hero-position {
    background-size: 100% 100%;
}

.tree-position {
    background-position-x: 23%;
    background-position-y: 95%;
}

.tree-position-mobile {
    background-position: 74% 100%;
}

.email-input:focus~.placeholder {
    display: none;
}

  .text-title {
    font-size: theme('fontSize.3xl')
  }
  .text-title-2 {
    font-size: 1.4rem;
    line-height:1.75rem !important;
  }

  @screen xs {
    /* Small screens (sm) */
    .text-title {
      font-size: theme('fontSize.5xl')
    }
    .text-title-2 {
      font-size: theme('fontSize.3xl');
      line-height: 2rem !important
    }
  }

  @screen sm {
    /* Small screens (sm) */
    .text-title {
      font-size: theme('fontSize.4xl');
    }
    .text-title-2 {
      font-size: theme('fontSize.4xl');
    }
  }
  
  @screen md {
    /* Medium screens (md) */
    .text-title {
      font-size: theme('fontSize.5xl');
    }
    .text-title-2 {
      font-size: 2.75rem;
      line-height: 3rem !important;
    }
  }
  
  @screen lg {
    /* Large screens (lg) */
    .text-title {
      font-size: theme('fontSize.6xl');
    }
    .text-title-2 {
      font-size: theme('fontSize.6xl');
      line-height: 3.75rem !important;
    }
  }
  
  @screen xl {
    /* Extra-large screens (xl) */
    .text-title {
      font-size: theme('fontSize.7xl');
    }
    .text-title-2 {
      font-size: theme('fontSize.6xl');
      line-height: 4rem !important;
    }
    
}
@screen xxl {
    /* Extra-large screens (xl) */
    .text-title {
      font-size: theme('fontSize.7xl');
    }
    .text-title-2 {
      font-size: theme('fontSize.6xl');

    }

}
@screen xxxl {
  /* Extra-large screens (xl) */
  .text-title {
    font-size: theme('fontSize.8xl');
  }
  .text-title-2 {
    font-size: theme('fontSize.6xl');
    line-height: 4.5rem !important;
  }

}